import { Link } from 'react-router-dom'

import logo_produce from '../img/logo_produce.svg'
import logo_pnipa from '../img/logo_pnipa.svg'
import logo_quipu from '../img/logo_quipu.svg'
import logo_bicentenario from '../img/logo_bicentenario.svg'
import logo_bancomundial from '../img/logo_bancomundial.svg'

export default function NavigationBar() {
  return (
    <>
      <div className='container-fluid'>
        <div className='navigation-section row'>
          <div className="col-12 mt-3 mb-3 d-flex justify-content-center align-items-center">
              <a href="https://www.gob.pe/produce" target='_blank' rel="noreferrer">
                <img className="navigation-logo logo-produce" src={logo_produce} alt="link produce"></img>
              </a>
              <a href="https://pnipa.gob.pe/" target='_blank' rel="noreferrer">
                <img className="navigation-logo logo-pnipa" src={logo_pnipa} alt="link pnipa"></img>
              </a>
              <a href="https://www.bancomundial.org" target='_blank' rel="noreferrer">
                <img className="navigation-logo logo-banco" src={logo_bancomundial} alt="link banco mundial"></img>
              </a>
              <a href="https://www.gob.pe" target='_blank' rel="noreferrer">
                <img className="navigation-logo logo-pueblo" src={logo_quipu} alt="link gobierno del peru"></img>
              </a>
              <a href="https://bicentenario.gob.pe/" target='_blank' rel="noreferrer">
                <img className="navigation-logo logo-bicentenario" src={logo_bicentenario} alt="link bicentenario del peru"></img>
              </a>
          </div>
          <div className="navigation-links col-12 d-flex justify-content-center">
            <ul className="col-7 d-flex justify-content-center align-items-center">
              <li className='col d-flex align-items-center justify-content-center'>
                <Link to="/">INICIO</Link>
              </li>
              <li className='col d-flex align-items-center justify-content-center'>
                <a href="#intro-section">COSECHANDO</a>
              </li>
              <li className='col d-flex align-items-center justify-content-center'>
                <a href="#value-section">
                  <div>NUESTRAS CADENAS
                    <br/>
                    DE VALOR
                  </div>
                </a>
              </li>
              <li className='col d-flex align-items-center justify-content-center'>
                <a href="#event-section">EVENTOS</a>
              </li>
              <li className='col d-flex align-items-center justify-content-center'>
                <a href="#reg-section">INSCRIPCIÓN</a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </>
  )
}
