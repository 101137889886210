
export default function RegistrationForm() {
  return (
    <div className="section reg-section row justify-content-center" id="reg-section">
      <div className="col-8">
        <h2>Inscripción</h2>
        <h3>Ficha de inscripción</h3>
        <form className="d-flex flex-wrap justify-content-center">
          <div className="col-6">
            <div className="me-5 mb-2">
              <label className="col-12">Nombres*</label>
              <input className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-6">
            <div className="me-5 mb-2">
              <label className="col-12">Apellidos*</label>
              <input className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-6">
            <div className="me-5 mb-2">
              <label className="col-12">Email*</label>
              <input className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-6">
            <div className="me-5 mb-2">
              <label className="col-12">Teléfono*</label>
              <input className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-6">
            <div className="me-5 mb-2">
              <label className="col-12">
              Nombre de la empresa / Institución*
              </label>
              <input className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-6">
            <div className="me-5 mb-2">
              <label className="col-12">Cargo*</label>
              <input className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-12">
            <div className="me-5 mb-2">
              <label className="col-12">Asunto*</label>
              <textarea className="col-12" placeholder="" required/>
            </div>
          </div>
          <div className="col-12">
            <div className="me-5 mb-2 pe-5">
              <label className="col-12">Mensaje*</label>
             <textarea className="col-12" placeholder="" required/>
            </div>
          </div>
          <div>
            <input className="reg-btn" value="Enviar" type="submit"/>
          </div>
        </form>
      </div>
    </div>
  );
}
