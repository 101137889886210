import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.min.js";
import "./fonts.css"
import "./styles.css"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavigationBar from "./components/NavigationBar";
import HomeView from "./views/HomeView";

export default function App() {
  return (
    <Router>
      <NavigationBar />
      {/* <div className="container mt-4"> */}
        <Routes>
          <Route path="/" element={<HomeView />} />
        </Routes>
      {/* </div> */}
    </Router>
  );
}
