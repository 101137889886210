import { useState } from 'react';
import Carousel  from 'react-bootstrap/Carousel';
import banner_lago_arapa from '../img/banner_lago_arapa.jpg'
import banner_langostino from '../img/banner_langostino.jpg'
import icon_footer_logo_cosechando from '../img/icon_footer_logo_cosechando.svg'
import icon_eventos_logo from '../img/icon_eventos_logo.svg'
import icon_cadena_trucha from '../img/icon_cadena_trucha.svg'
import icon_cadena_langostino from '../img/icon_cadena_langostino.svg'
import icon_cadena_pota from '../img/icon_cadena_pota.svg'
import icon_cadena_amazonicas from '../img/icon_cadena_amazonicas.svg'
import icon_cadena_macroalgas from '../img/icon_cadena_macroalgas.svg'
import icon_cadena_conchadeabanico from '../img/icon_cadena_conchadeabanico.svg'

export default function MainSlider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <div className='slider-slide-first d-flex align-items-center justify-content-center' style={{ backgroundImage:`url(${banner_lago_arapa})` }}>
          <div className="col-5 text-center">
            <img className='slider-slogan mx-2' src={icon_footer_logo_cosechando} alt="logo cosechando"></img>
          </div>
          <div className="col-5">
            <div className='slider-title'>+ de 1900</div>
            <span className='slider-text-red'>proyectos de innovación</span>
            <div className='slider-text-white'>valorizados en + de 300 millones de soles.</div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='slider-slide-second d-flex flex-column align-items-center' style={{ backgroundImage:`url(${banner_langostino})` }}>
          <img className='slider-slogan mx-2' src={icon_eventos_logo} alt="event logo"></img>
          <div className='slider-title'>- Cadenas de valor -</div>
          <div className='d-flex col-8 justify-content-center'>
            <img className='slider-icon mx-3' src={icon_cadena_trucha} alt="icon trucha"/>
            <img className='slider-icon mx-3' src={icon_cadena_langostino} alt="icon langostino"/>
            <img className='slider-icon mx-3' src={icon_cadena_pota} alt="icon pota"/>
            <img className='slider-icon mx-3' src={icon_cadena_amazonicas} alt="icon paiche"/>
            <img className='slider-icon mx-3' src={icon_cadena_macroalgas} alt="icon macroalgas"/>
            <img className='slider-icon mx-3' src={icon_cadena_conchadeabanico} alt="icon concha"/>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

