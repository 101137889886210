import React, { useState, useRef, useEffect } from 'react'

export default function Timer() {
  const Ref = useRef(null);

  const [timer, setTimer] = useState(
    {
      'days': '00',
      'hours': '00',
      'mins': '00'
    }
  );
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor((total / 1000 / 60 / 60 /24) % 24);
    return {
      total, days, hours, minutes
    };
  }

  const startTimer = (e) => {
    let { total, days, hours, minutes }
          = getTimeRemaining(e);
    if (total >= 0) {

      setTimer(
        {
          'days': (days > 9 ? days : '0' + days),
          'hours': (hours > 9 ? hours : '0' + hours),
          'mins': (minutes > 9 ? minutes : '0' + minutes)
        }
      )
    }
  }

  const clearTimer = (e) => {
    setTimer(
      {
        'days': '00',
        'hours': '00',
        'mins': '01'
      }
    )
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date('July 22, 2022 00:00:00 GMT-0500');
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  }

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  }

  return (
    
    <div className="timer-section row justify-content-center">
      {console.log(new Date())}
      <div className='timer-hidden'>
        <div>{timer.days}</div>
        <div>{timer.hours}</div>
        <div>{timer.mins}</div>
        <button onClick={onClickReset}>Reset</button>
      </div>
      
      <div className="d-flex justify-content-center align-items-center">
        <div className="timer-card text-center">
          <div className='timer-number'>{timer.days}</div>
          <div className='timer-text'>DÍAS</div>
        </div>
        <div className='timer-colon'>:</div>
        <div className="timer-card text-center">
          <div className='timer-number'>{timer.hours}</div>
          <div className='timer-text'>HORAS</div>
        </div>
        <div className='timer-colon'>:</div>
        <div className="timer-card text-center">
          <div className='timer-number'>{timer.mins}</div>
          <div className='timer-text'>MINUTOS</div>
        </div>
      </div>
    </div>
  );
}
