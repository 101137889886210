import RegistrationForm from "../components/RegistrationForm";
import Timer from "../components/Timer";
import MainSlider from "../components/MainSlider";
import FooterSection from "../components/FooterSection";

import video_pnipa from '../img/video_pnipa.png';
import icon_intro_play from '../img/icon_intro_play.svg';
import icon_actividades_1 from '../img/icon_actividades_1.svg';
import icon_actividades_2  from '../img/icon_actividades_2.svg'
import icon_actividades_3 from '../img/icon_actividades_3.svg'
import icon_actividades_4 from '../img/icon_actividades_4.svg'
import icon_actividades_4_logo from '../img/icon_actividades_4_logo.svg'
import icon_intro_btnmas from '../img/icon_intro_btnmas.svg'

import icon_cadena_trucha from '../img/icon_cadena_trucha.svg'
import icon_cadena_pota from '../img/icon_cadena_pota.svg'
import icon_cadena_langostino from '../img/icon_cadena_langostino.svg'
import icon_cadena_amazonicas from '../img/icon_cadena_amazonicas.svg'
import icon_cadena_macroalgas from '../img/icon_cadena_macroalgas.svg'
import icon_cadena_conchadeabanico from '../img/icon_cadena_conchadeabanico.svg'
import icon_slash_red from '../img/icon_slash_red.svg'

import icon_eventos_logo from '../img/icon_eventos_logo.svg'
import icon_eventos_ubicacion from '../img/icon_eventos_ubicacion.svg'
import icon_eventos_calendario from '../img/icon_eventos_calendario.svg'
import icon_eventos_inscripcion from '../img/icon_eventos_inscripcion.svg'
import icon_eventos_descargar from '../img/icon_eventos_descargar.svg'

export default function HomeView() {
  return (
    <>
      <MainSlider />
      <div className="container-fluid">
        <div className="intro-section row justify-content-center" id="intro-section">
          <div className="d-flex flex-wrap col-9 align-items-center justify-content-center">
            <div className="img-container col-6">
              <img className="intro-img-video" src={video_pnipa} alt="video_pnipa"></img>
              <img className="intro-icon-play" src={icon_intro_play} alt="icon play"></img>
            </div>
            <div className="col-6 ps-4">
              <p>
                Cinco años de gestión han fortalecido la ruta que llevará al
                Perú a ser un país reconocido por su alta productividad pesquera
                y acuícola. Ha sido un camino de compromiso, esfuerzo y
                sinergia, donde hemos demostrado que la pesca y acuicultura
                pueden convertirse en verdaderos motores de crecimiento de la
                economía peruana.
              </p>
              <p className="intro-p">
                Es así como nace <span>“Cosechando Innovación”</span>, una serie
                de eventos de rendición de cuentas y transferencia de resultados
                donde tú eres el protagonista.
              </p>
              <div className="d-flex align-items-center">
                <p className="intro-last-p">¡Cosechemos juntos!</p>
                <button className="intro-btn d-flex align-items-center justify-content-center">
                  <img className="btn-more" src={icon_intro_btnmas} />
                  Lee más
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="section act-section row justify-content-center" id="act-section">
          <div className="col-9">
            <h2>Actividades</h2>
            <p>
              Cosechando innovación es parte de una serie de 9 eventos
              regionales que buscan transferir los resultados de la primera fase
              del PNIPA por cadena de valor a través de los más de 1900
              proyectos cofinanciados. En cada evento, podrás conocer los
              resultados de la cadena de valor priorizada de la región,
              intercambiar experiencias y lecciones aprendidas de los proyectos
              desarrollados y participar en el reconocimiento de los mejores
              proyectos PNIPA.
            </p>
            <div className="act-icons d-flex flex-wrap align-items-center justify-content-between">
              <div className="act-icon">
                <img src={icon_actividades_1} />
                <div>
                  <span>Presentación</span> de resultados PNIPA
                </div>
              </div>
              <div className="act-icon">
                <img src={icon_actividades_2} />
                <div>
                  <span>Intercambio</span> de experiencias de innovación
                </div>
              </div>
              <div className="act-icon">
                <img src={icon_actividades_3} />
                <div>
                  <span>Reconocimiento</span> a proyectos emblemáticos PNIPA
                </div>
              </div>
              <div className="act-icon">
                <img src={icon_actividades_4} />
                <div>
                  <span>Feria</span>
                </div>
                <img
                  className="act-logo"
                  src={icon_actividades_4_logo}
                  alt="logo cosechando innovación"
                />
              </div>
            </div>
          </div>

          <div className="act-bottom col-12 d-flex text-center justify-content-center">
            <div className="d-flex col-9 align-items-center justify-content-center">
              <div className="d-flex col-6 align-items-center justify-content-center">
                <div>
                  <div className="act-bottom-plus">+</div>
                  <div className="act-bottom-de">de</div>
                </div>
                <div className="act-bottom-number">1900</div>
                <div className="act-bottom-text">
                  proyectos de <br />
                  innovación
                </div>
              </div>
              <div className="d-flex col-6 align-items-center justify-content-center">
                <div>
                  <div className="act-bottom-plus">+</div>
                  <div className="act-bottom-de">de</div>
                </div>
                <div className="act-bottom-number">300</div>
                <div className="act-bottom-text">
                  millones <br />
                  de soles
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section value-section row justify-content-center" id="value-section">
          <div className="col-9">
            <h2>Nuestras cadenas de valor</h2>
            <p>
              La acuicultura y pesca en el Perú representan un importante aporte
              al crecimiento del país, es por ello, que desde el PNIPA venimos
              promoviendo la innovación en 6 cadenas de valor priorizadas a
              través del cofinanciamiento de más de 1900 proyectos a nivel
              nacional.
            </p>
            <div className="value-items d-flex col-12 flex-column">
              <div className="value-item d-flex">
                <img src={icon_cadena_trucha} />
                <div className="value-item-text ms-5">
                  <div className="value-name">Trucha</div>
                  <div className="d-flex align-items-center justify-content-start me-5">
                    <div className="value-slash">
                      <img src={icon_slash_red} alt="slash icon"/>
                    </div>
                    <div>
                      <div className="value-plus">+</div>
                      <div className="value-de">de</div>
                    </div>
                    <div className="value-number">700</div>
                    <div className="value-text">proyectos</div>
                  </div>
                </div>
              </div>
              <div className="value-item d-flex">
                <img src={icon_cadena_langostino} />
                <div className="value-item-text ms-5">
                  <div className="value-name col-5">Langostino</div>
                  <div className="d-flex align-items-center justify-content-start me-5">
                    <div className="value-slash">
                      <img src={icon_slash_red} alt="slash icon"/>
                    </div>
                    <div>
                      <div className="value-plus">+</div>
                      <div className="value-de">de</div>
                    </div>
                    <div className="value-number">27</div>
                    <div className="value-text">proyectos</div>
                  </div>
                </div>
              </div>
              <div className="value-item d-flex">
                <img src={icon_cadena_pota} />
                <div className="value-item-text ms-5">
                  <div className="value-name col-5">Pota y perico</div>
                  <div className="d-flex align-items-center justify-content-start me-5">
                    <div className="value-slash">
                      <img src={icon_slash_red} alt="slash icon"/>
                    </div>
                    <div>
                      <div className="value-plus">+</div>
                      <div className="value-de">de</div>
                    </div>
                    <div className="value-number">51</div>
                    <div className="value-text">proyectos</div>
                  </div>
                </div>
              </div>
              <div className="value-item d-flex">
                <img src={icon_cadena_amazonicas} />
                <div className="value-item-text ms-5">
                  <div className="value-name col-4">Especies amazónicas</div>
                  <div className="d-flex align-items-center justify-content-start me-5">
                    <div className="value-slash">
                      <img src={icon_slash_red} alt="slash icon"/>
                    </div>
                    <div>
                      <div className="value-plus">+</div>
                      <div className="value-de">de</div>
                    </div>
                    <div className="value-number">460</div>
                    <div className="value-text">proyectos</div>
                  </div>
                </div>
              </div>
              <div className="value-item d-flex">
                <img src={icon_cadena_macroalgas} />
                <div className="value-item-text ms-5">
                  <div className="value-name col-5">Macroalgas</div>
                  <div className="d-flex align-items-center justify-content-start me-5">
                    <div className="value-slash">
                      <img src={icon_slash_red} alt="slash icon"/>
                    </div>
                    <div>
                      <div className="value-plus">+</div>
                      <div className="value-de">de</div>
                    </div>
                    <div className="value-number">29</div>
                    <div className="value-text">proyectos</div>
                  </div>
                </div>
              </div>
              <div className="value-item d-flex">
                <img src={icon_cadena_conchadeabanico} />
                <div className="value-item-text last ms-5">
                  <div className="value-name col-5">Concha de abanico</div>
                  <div className="d-flex align-items-center justify-content-start me-5">
                    <div className="value-slash">
                      <img src={icon_slash_red} alt="slash icon"/>
                    </div>
                    <div>
                      <div className="value-plus">+</div>
                      <div className="value-de">de</div>
                    </div>
                    <div className="value-number">25</div>
                    <div className="value-text">proyectos</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="value-bottom col-12 d-flex justify-content-center">
            <div className="value-section-item d-flex col-9 align-items-center justify-content-center">
              <div className="value-bottom-number">11</div>
              <div className="value-bottom-text col-7">
                tecnologías consolidadas que mejoran la competitividad y
                sostenibilidad en 8 cadenas de valor
              </div>
            </div>
          </div>
        </div>

        <div className="section event-section row justify-content-center" id="event-section">
          <div className="col-9">
            <h2>Eventos</h2>
            <div className="event-items d-flex flex-wrap">
              <div className="col-4">
                <div className="event-item event-item-bg-lo me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name">Trucha</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Huancayo</div>
                    </div>
                    <div className="event-date col-6">
                      <div className="event-day">21</div>
                      <div className="event-month">de julio</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Incríbete
                        <br />
                        aquí
                      </button>
                    </div>

                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div>
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-lo me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name">Trucha</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Cusco</div>
                    </div>
                    <div className="event-date col-6">
                      <div className="event-day">11</div>
                      <div className="event-month">de agosto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-lb me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name">Langostino</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Cusco</div>
                    </div>
                    <div className="event-date col-6">
                      <div className="event-day">26</div>
                      <div className="event-month">de agosto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-lg me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name-coming">Pota y perico</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Arequipa</div>
                    </div>
                    <div className="event-date col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_calendario}
                        alt="calendar icon"
                      />
                      <div className="event-month">Muy pronto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-do me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name-medium event-name-coming">Especies amazónicas</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">San Martín</div>
                    </div>
                    <div className="event-date col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_calendario}
                        alt="calendaricon"
                      />
                      <div className="event-month">Muy pronto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-dg me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name-coming">Macroalgas</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Pisco</div>
                    </div>
                    <div className="event-date col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_calendario}
                        alt="calendaricon"
                      />
                      <div className="event-month">Muy pronto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-db me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name-short event-name-coming">Pota y perico /<br/>concha de abanico</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Piura</div>
                    </div>
                    <div className="event-date col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_calendario}
                        alt="calendar icon"
                      />
                      <div className="event-month">Muy pronto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-lo me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name-coming">Trucha</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Puno</div>
                    </div>
                    <div className="event-date col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_calendario}
                        alt="calendar pin icon"
                      />
                      <div className="event-month">Muy pronto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>

              <div className="col-4">
                <div className="event-item event-item-bg-lo me-3 mb-3">
                  <img
                    className="event-logo"
                    src={icon_eventos_logo}
                    alt="cosechando slogan"
                  />
                  <div className="event-name-coming">Trucha</div>
                  <div className="d-flex align-items-end mb-3">
                    <div className="event-place col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_ubicacion}
                        alt="location pin icon"
                      />
                      <div className="event-city">Cajamarca</div>
                    </div>
                    <div className="event-date col-6">
                      <img
                        className="event-icon"
                        src={icon_eventos_calendario}
                        alt="calendar icon"
                      />
                      <div className="event-month">Muy pronto</div>
                    </div>
                  </div>
                  <div className="event-register d-flex justify-content-around">
                    <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_inscripcion}
                        />
                        Inscripción
                        <br />
                        (proximamente)
                      </button>
                    </div>

                    {/* <div className="col-6 d-flex justify-content-center">
                      <button className="event-btn-register d-flex align-items-center">
                        <img
                          className="btn-register-icon"
                          src={icon_eventos_descargar}
                        />
                        Descargar <br />
                        programa aquí
                      </button>
                    </div> */}
                  </div>
                  <div className="event-footer">* Aforo limitado</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RegistrationForm />
        <Timer />
        <FooterSection/>
      </div>
    </>
  );
}
