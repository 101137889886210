import icon_footer_logo_cosechando from '../img/icon_footer_logo_cosechando.svg'
import icon_sn_facebook from '../img/icon_sn_facebook.svg'
import icon_sn_linkedin from '../img/icon_sn_linkedin.svg'
import icon_sn_twitter from '../img/icon_sn_twitter.svg'
import icon_sn_instagram from '../img/icon_sn_instagram.svg'
import logo_bw_produce from '../img/logo_bw_produce.svg'
import logo_bw_pnipa from '../img/logo_bw_pnipa.svg'
import logo_bw_pueblo from '../img/logo_bw_pueblo.svg'
import logo_bw_bicentenario from '../img/logo_bw_bicentenario.svg'
import logo_bancomundial from '../img/logo_bancomundial.svg'

export default function FooterSection() {
  return (
    <footer>
      <div className="section footer-section row justify-content-center">
        <div className="col-9">
          <div className="d-flex flex-wrap mb-4 align-items-center">
            <div className="col-6 pe-4 text-center">
              <img className='footer-slogan' src={icon_footer_logo_cosechando} alt="video_pnipa"></img>
            </div>
            <div className="footer-contact col-6">
              <h3>Contáctanos</h3>
              <p className='col-9'>
                Ante cualquier consulta o duda sobre tu inscripción, contacta al
                equipo organizador al correo:
              </p>
              <span className='footer-email'>comunicacionespnipa@pnipa.gob.pe</span>
              <div className="d-flex align-items-center mt-4">
                <div className='me-2'>Síguenos en:</div>
                <div className="d-flex align-items-center">
                  <a href="https://www.facebook.com/PNIPAPeru" target='_blank' rel="noreferrer">
                    <img className="footer-sn-icon" src={icon_sn_facebook} alt="facebook icon"></img>
                  </a>
                  <a href="https://www.linkedin.com/in/pnipaperu" target='_blank' rel="noreferrer">
                    <img className="footer-sn-icon" src={icon_sn_linkedin} alt="linkedin icon"></img>
                  </a>
                  <a href="https://twitter.com/pnipaperu" target='_blank' rel="noreferrer">
                    <img className="footer-sn-icon" src={icon_sn_twitter} alt="twiteer icon"></img>
                  </a>
                  <a href="https://www.instagram.com/pnipaperu" target='_blank' rel="noreferrer">
                    <img className="footer-sn-icon" src={icon_sn_instagram} alt="instagram icon"></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-links-text col-12 mt-5 text-center">Organizado por:</div>
            <div className="col-12 mt-4 mb-5 d-flex justify-content-center align-items-center">
              <a href="https://www.gob.pe/produce" target='_blank' rel="noreferrer">
                <img className="footer-logo logo-produce" src={logo_bw_produce} alt="link produce"></img>
              </a>
              <a href="https://pnipa.gob.pe/" target='_blank' rel="noreferrer">
                <img className="footer-logo logo-pnipa" src={logo_bw_pnipa} alt="link pnipa"></img>
              </a>
              <a href="https://www.bancomundial.org" target='_blank' rel="noreferrer">
                <img className="footer-logo logo-banco" src={logo_bancomundial} alt="link banco mundial"></img>
              </a>
              <a href="https://www.gob.pe" target='_blank' rel="noreferrer">
                <img className="footer-logo logo-pueblo" src={logo_bw_pueblo} alt="link gobierno del peru"></img>
              </a>
              <a href="https://bicentenario.gob.pe/" target='_blank' rel="noreferrer">
                <img className="footer-logo logo-bicentenario" src={logo_bw_bicentenario} alt="link bicentenario del peru"></img>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center">
            PNIPA © Copyright 2022 (All rights reserved).
        </div>
      </div>
    </footer>
  );
}
